import { makeStyles } from '@material-ui/core'
import BlackLabel from './BlackLabel'
import theme from '../theme'
import cx from 'clsx'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '150px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '112px',
    },
  },
  title: {
    marginBottom: '1rem',
  },
  image: {
    maxWidth: '202px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      height: 'auto',
    },
  },
  small: {
    '& $image': {
      maxWidth: '75px',
    },
  },
  transform: {
    '& $image': {
      transform: 'scaleX(-1)',
    },
  },
})

const Dude = ({ className = '', title = '', small = false, transform = false }) => {
  const cls = useStyles()
  return (
    <div className={`${cx({
      [cls.root]: true,
      [cls.small]: !!small,
      [cls.transform]: transform,
    })} ${className}`}>
      {title ? (
        <BlackLabel className={cls.title}>{title}</BlackLabel>
      ) : null}
      <img className={cls.image} src={require(`../assets/img/dude-black.png`)} alt={'dude'} />
    </div>
  )
}

export default Dude
