import { Grid, makeStyles, Typography } from '@material-ui/core'
import cx from 'clsx'
import BigNumber from './BigNumber'
import WhenVisible from './WhenVisivible'
import Dude from './Dude'
import theme from '../theme'
import Curve from './Curve'
// import { useMemo } from 'react'

import Markdown from 'react-markdown'


const useStyles = makeStyles({
  root: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      // minHeight: '100vh',
      '& > *': {
        position: 'relative',
        zIndex: 2,
      },
    },
  },

  odd: {

  },

  hero: {
    order: 0,
  },

  col1: {
    '$odd &': {
      order: 2,
      [theme.breakpoints.down('sm')]: {
        order: '1',
      },
    },
  },

  col2: {
    [theme.breakpoints.down('sm')]: {
      order: '2',
    },
  },

  dudesWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '& > div:nth-child(2)': {
      marginTop: '-70px',
      marginLeft: '-40px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '110px',
    },
  },

  dudeWithCar: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column-reverse',
      alignItems: 'flex-end',
    },
    '& > img': {
      position: 'absolute',
      right: '5rem',
      bottom: '-110px',
      [theme.breakpoints.down('sm')]: {
        position: 'static',
        margin: '-110px auto 0',
      },
      [theme.breakpoints.down('xs')]: {
        position: 'static',
        margin: '-30px auto 0',
        maxWidth: '100%',
        height: 'auto',
      },
    },
  },

  numberWrapper: {

  },

  even: {},

  number: {
    position: 'absolute',
    width: 100,
    height: 100,

    '$even &': {
      top: '3rem',
      left: '3rem',
      [theme.breakpoints.down('sm')]: {
        left: '0',
        top: '1rem ',
        right: 'auto',
        bottom: 'auto',
      },
      [theme.breakpoints.down('xs')]: {
        left: '16px',
      },
    },


    '$odd &': {
      top: 'auto' ,
      left: 'auto',
      bottom: '3rem',
      right: '3rem',
      [theme.breakpoints.down('sm')]: {
        left: '0',
        top: '1rem ',
        right: 'auto',
        bottom: 'auto',
      },
      [theme.breakpoints.down('xs')]: {
        left: '16px ',
      },
    },
  },

  curve: {
    position: 'absolute',
    width: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 0,
    '$odd &': {
      transform: 'translateX(-50%) scaleY(-1)',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      top: '15%',
    },
  },

  imageWrapper: {
    display: 'inline-block',
    maxWidth: '100%',
  },

  image: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        height: 'auto',
      },
    },
  },

  defaultContent1: {
    // backgroundColor: '#8e121240',
    '& $number': {
      top: '7rem',
    },
    [theme.breakpoints.down('sm')]: {
      '& $number': {
        top: '7rem',
      },
      '& $curve': {
        top: '20%',
      },
    },

  },
  defaultContent2: {
    // backgroundColor: '#8e121240',
    '& $image': {
      maxWidth: 520,
      transform: 'translateX(-20%)',
      [theme.breakpoints.down(1489)]: {
        transform: 'translateX(-10%)',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '25% auto 0',
        transform: 'rotate(25deg)',
        display: 'block',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '200px',
        margin: '10% auto -40px',
      },
    },
    '& $curve': {
      [theme.breakpoints.down('xs')]: {
        top: '-6%',
      },
    },
  },
  defaultContent3: {
    // backgroundColor: '#8e121240',
    '& $image': {
      maxWidth: 520,
      transform: 'translateX(60%)',
      [theme.breakpoints.down(1078)]: {
        transform: 'translateX(30%)',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '10% auto 0',
        display: 'block',
        transform: 'rotate(-25deg)',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '200px',
        margin: '10% auto -40px',
      },
    },
  },
  defaultContent4: {
    // backgroundColor: '#8e121240',
    padding: '150px 0 90px',
    '& $col2': {
      position: 'relative',
      zIndex: 1,
    },
    [theme.breakpoints.up('sm')]: {
      '& $number': {
        whiteSpace: 'nowrap',
        top: 0,
      },
    },
  },
})

const DefaultContentBlock = ({
  children = null,
  hero = '',
  hero_sub = '',
  title = '',
  number = '',
  text = '',
  image = '',
  odd = false,
  dudes = [],
  className = '',
  dudeWithCar = false,
  curve = false,
  defaultContent1 = false,
  defaultContent2 = false,
  defaultContent3 = false,
  defaultContent4 = false,
  imageClassName = null,
}) => {
  const cls = useStyles()
  // const numberStyle = useMemo(() => {
  //   return odd ? {
  //     position: 'absolute',
  //     bottom: '3rem',
  //     right: '3rem',
  //     width: 100,
  //     height: 100,
  //   } : {
  //     position: 'absolute',
  //     top: '3rem',
  //     left: '3rem',
  //     width: 100,
  //     height: 100,
  //   }
  // }, [odd])
  return (

    <Grid container spacing={4} className={`${cx({
      [cls.root]: true,
      [cls.odd]: odd,
      [cls.even]: !odd,
      [cls.defaultContent1]: defaultContent1,
      [cls.defaultContent2]: defaultContent2,
      [cls.defaultContent3]: defaultContent3,
      [cls.defaultContent4]: defaultContent4,
    })} ${className}`}>
      {hero ? (
        <Grid item xs={12} className={cls.hero}>
          <Typography align='center' variant='h1'>
            {hero}
          </Typography>
          {hero_sub ? (
            <div style={{ maxWidth: '50%', margin: '0 auto' }}>
              <Typography align='center' variant='subtitle2'>
                {hero_sub}
              </Typography>
            </div>
          ):null}
        </Grid>
      ):null}
      {image || dudes?.length ? (
        <Grid item xs={12} md={6} className={cls.col1}>
          {image && !dudeWithCar ? (
          // <div className={cx(cls.imageWrapper, imageClassName)}>
          // <div className={cx(cls.image)}>

            <img
              className={cx(cls.image)}
              src={image}
            />
            // {/* </div> */}
          // </div>
          ):null}
          {dudes.length ? (
            <div className={cls.dudesWrapper}>
              {dudes.map((dude, i) => <Dude key={i} title={dude} />) }
            </div>
          ):null}
          {dudeWithCar ? (
            <div className={cls.dudeWithCar}>
              {/* <div className={cx(cls.imageWrapper, imageClassName)}> */}
              {/* <div > */}

              <img
                className={cx(cls.image)}
                src={image}
              />
              {/* </div> */}
              {/* </div> */}
              <Dude title={'Kunde'} />
            </div>
          ):null}
        </Grid>
      ):null}
      {(title || text || children) ? (
        <Grid item xs={12} md={6} className={cls.col2}>

          <WhenVisible
            variant={odd ? 'fromRight' : 'fromLeft' }
          >
            <Typography variant='h3'>{title}</Typography>
          </WhenVisible>
          <WhenVisible
            variant={odd ? 'fromRight' : 'fromLeft' }
            delay={.2}
          >
            <Typography variant={'subtitle2'}>
              <Markdown>{children || text}</Markdown>
            </Typography>
          </WhenVisible>

        </Grid>
      ):null}
      {curve && <Curve className={cls.curve}/>}

      {number ? (
      // <div style={numberStyle}>
      //   <WhenVisible
      //     className={cls.number}
      //     variant={odd ? 'fromLeft' : 'fromRight'}
      //     delay={0.3}

        //   >
        <BigNumber className={cls.number}>
          {number}
        </BigNumber>
        // {/* </WhenVisible> */}
        // {/* </div> */}
      ) : null}

    </Grid>


  )
}

export default DefaultContentBlock
