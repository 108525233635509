import { makeStyles } from '@material-ui/core'
import theme from '../theme'

const useStyles = makeStyles({
  root: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: 1,
    letterSpacing: 0,
    color: '#ffffff',
    textAlign: 'center',
    padding: '12px 32px',
    backgroundColor: '#000000',
    borderRadius: '45px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.8571rem',
      padding: '5px 13px',
    },
  },
})

const BlackLabel = ({
  children = null,
  className = '',
}) => {
  const cls = useStyles()
  return (
    <span className={`${cls.root} ${className}`}>{children}</span>
  )
}

export default BlackLabel
