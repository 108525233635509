import { motion, useAnimation } from 'framer-motion'
import { useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'


const animationVariants = {
  fromRight: {
    visible: delay => {
      return { opacity: 1, translateX: 0, transition: { delay } }
    },
    hidden: { opacity: 0, translateX: -300 },
  },
  fromLeft: {
    visible: delay => ({ opacity: 1, translateX: 0, transition: { delay } }),
    hidden: { opacity: 0, translateX: 300 },
  },
  fromBottom: {
    visible: delay => ({ opacity: 1, translateY: 0, transition: { delay } }),
    hidden: { opacity: 0, translateY: 300 },
  },
}

const WhenVisible = ({
  children,
  variant = 'fromLeft',
  delay = 0,
  className = '',
  style = null,
}) => {

  const controls = useAnimation()
  const [ref, inView] = useInView({
    delay: 500,
  })


  const variants = useMemo(() => animationVariants[variant], [variant])

  useEffect(() => {

    if (inView) {
      controls.start('visible')
    } else {
      controls.set('hidden')
    }
  }, [controls, inView])

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial='hidden'
      transition={{ duration: 0.25 }}
      variants={variants}
      custom={delay }
      className={className}
      style={style}
    >
      {children}
    </motion.div>
  )
}


export default WhenVisible