import { makeStyles } from '@material-ui/core'
import theme from '../theme'


const useStyles = makeStyles({
  root: {
    fontFamily: `'Montserrat', sans-serif`,
    fontSize: '11.25rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '8.7143rem',
    },
    [theme.breakpoints.down(1280)]: {
      fontSize: '5.7143rem',
    },
  },
})

const BigNumber = ({
  children = null,
  className = '',
}) => {
  const cls = useStyles()
  return (
    <span className={`${cls.root} ${className}`}>{children}</span>
  )
}

export default BigNumber
