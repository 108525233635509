import { Button, CircularProgress, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import Screen from './Screen'
import theme from '../theme'
import { useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import axios from 'axios'
import cx from 'clsx'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('xs')]: {
      padding: '5rem 0',
    },
  },

  _submitted: {},

  title: {
    marginBottom: '2.05rem',
  },
  inputWrapper: {
    marginBottom: '1.05rem',
    textAlign: 'center',
    transition: 'all .3s',
    opacity: 1,

    '& input': {
      fontSize: '2rem',
      textAlign: 'center',
      padding: '12px 0 14px',
    },

    '$_submitted &': {
      transform: 'translateY(-50px)',
      opacity: 0,
    },
  },

  button: {
    transition: 'all .3s',
    '$_submitted &': {
      transform: 'translateY(-50px)',
    },
  },
})

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
const isValid = (email = '') => {
  return re.test(email)
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Feedback = ({ title = 'Ein kostenloses Audit anfordern' }) => {
  const cls = useStyles()
  const { pathname } = useRouter()
  const [email, setEmail] = useState(``)
  const [isLoading, setLoading] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const handleChange = useCallback(e => setEmail(e.target.value), [])
  const handleSubmit = useCallback(e => {
    e.preventDefault()
    if(!email || !isValid(email)) {
      return
    }
    setLoading(true)
    axios.post(`/api/feedback`, {
      from: email,
      meta: {
        pathname,
      },
    })
      .then(() => {
        setSubmitted(true)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
  }, [email])
  return (
    <Screen className={cx({
      [cls.root]: true,
      [cls._submitted]: isSubmitted,
    })} >
      <Container>
        <Grid container alignItems={'center'}>
          <Grid item xs={12}>
            <Typography variant={'h1'} align='center' className={cls.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container justify={'center'} spacing={6}>
                <Grid item xs={12}>
                  <div className={cls.inputWrapper}>
                    <TextField
                      type={'email'}
                      placeholder={'hallo@supermail.de'}
                      onChange={handleChange}
                      value={email}
                      disabled={isLoading}
                    />
                  </div>
                </Grid>
                <Button
                  className={cls.button}
                  type='submit'
                  variant='contained'
                  color='primary'
                  size='large'
                  disabled={!isValid(email) || isLoading || isSubmitted}
                >
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    isSubmitted ?
                      (
                        `Formular erfolgreich gesendet!`
                      )
                      : `Absenden`
                  ) }
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Screen>
  )
}

export default Feedback
