const Curve = ({
  className = '',
}) => {
  return (
    <svg className={className} viewBox='0 0 320 237' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M-36 50.7529C37.1667 47.0862 249.5 50.7528 361.5 207.253' stroke='black' strokeOpacity='0.06' strokeWidth='100'/>
    </svg>
  )
}

export default Curve
